import React, { Component } from 'react';


class OnlineActivities extends Component {
    render() {
        return (
            <p>Welcome to Online Activities</p>
        );
    }

}

export default OnlineActivities